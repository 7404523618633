export default {
  calendar_view: 'calendar_view',
  calendar_updated: 'calendar_updated',

  checkout_load: 'checkout_load',
  checkout_product_selected: 'checkout_product_selected',
  checkout_purchase_start: 'checkout_purchase_start',
  checkout_purchase_success: 'checkout_purchase_success',
  checkout_purchase_failed: 'checkout_purchase_failed',
  checkout_view: 'checkout_view',

  add_service_view: 'add_service_view',
  add_service_purchase_start: 'add_service_purchase_start',
  add_service_purchase_success: 'add_service_purchase_success',
  add_service_purchase_failed: 'add_service_purchase_failed',

  doctor_validate_view: 'doctor_validate_view',
  doctor_validate_send: 'doctor_validate_send',
  doctor_validate_send_success: 'doctor_validate_send_success',
  doctor_validate_send_failed: 'doctor_validate_send_failed',

  home_last_result_selected: 'home_last_result_selected',
  home_cheapeast_result_selected: 'home_cheapeast_result_selected',
  home_highlight_result_selected: 'home_highlight_result_selected',
  home_location_current_permission: 'home_location_current_permission',
  home_location_request: 'home_location_request',
  home_location_request_result: 'home_location_request_result',

  last_purchased_space_click: 'last_purchased_space_click',

  login: 'login',

  my_habitat_pass_selected: 'my_habitat_pass_selected',

  my_hour_package_selected: 'my_hour_package_selected',
  my_hour_package_space_selected: 'my_hour_package_space_selected',

  page_view: 'page_view',

  profile_view: 'profile_view',
  profile_save: 'profile_save',
  profile_save_success: 'profile_save_success',
  profile_save_failed: 'profile_save_failed',

  profile_specs_view: 'profile_specs_view',
  profile_specs_save: 'profile_specs_save',
  profile_specs_save_success: 'profile_specs_save_success',
  profile_specs_save_failed: 'profile_specs_save_failed',

  profile_services_view: 'profile_services_view',
  profile_services_save: 'profile_services_save',
  profile_services_save_success: 'profile_services_save_success',
  profile_services_save_failed: 'profile_services_save_failed',

  result_view: 'result_view',
  result_schedule_visit: 'result_schedule_visit',
  result_purchase: 'result_purchase',

  receivers_view: 'receivers_view',
  receiver_details_view: 'receiver_details_view',

  receiver_add: 'receivers_add',
  receiver_add_success: 'receiver_add_success',
  receiver_add_failed: 'receiver_add_failed',

  receiver_remove: 'receiver_remove',
  receiver_remove_success: 'receiver_remove_success',
  receiver_remove_failed: 'receiver_remove_failed',

  receiver_banks_view: 'receiver_banks_view',
  receiver_bank_add: 'receiver_bank_add',
  receiver_bank_add_success: 'receiver_bank_add_success',
  receiver_bank_add_failed: 'receiver_bank_add_failed',
  receiver_bank_remove: 'receiver_bank_remove',
  receiver_bank_remove_success: 'receiver_bank_remove_success',
  receiver_bank_remove_failed: 'receiver_bank_remove_failed',

  receiver_documents_view: 'receiver_documents_view',
  receiver_document_send: 'receiver_document_send',
  receiver_document_send_success: 'receiver_document_send_success',
  receiver_document_send_failed: 'receiver_document_send_failed',

  search: 'search',
  search_result_selected: 'search_result_selected',

  signup_view: 'signup_view',
  signup: 'signup',
  signup_success: 'signup_success',
  signup_failed: 'signup_failed',

  saas_signup_view: 'saas_signup_view',
  saas_signup: 'saas_signup',
  saas_signup_success: 'saas_signup_success',
  saas_signup_failed: 'saas_signup_failed',

  space_view: 'space_view',
  space_save: 'space_save',
  space_save_success: 'space_save_success',
  space_save_failed: 'space_save_failed',
  space_sent_to_review: 'space_sent_to_review',
  space_removed_from_review: 'space_removed_from_review',
  space_pause_ad: 'space_pause_ad',
  space_resume_ad: 'space_resume_ad',

  space_habitat_pass_view: 'space_habitat_pass_view',
  space_habitat_pass_request: 'space_habitat_pass_request',
  space_habitat_pass_cancel: 'space_habitat_pass_cancel',
  space_habitat_pass_accept: 'space_habitat_pass_accept',
  space_habitat_pass_reject: 'space_habitat_pass_reject',

  unity_add_view: 'unity_add_view',
  unity_add: 'unity_add',
  unity_add_success: 'unity_add_success',
  unity_add_failed: 'unity_add_failed',

  unity_edit_view: 'unity_edit_view',
  unity_edit: 'unity_edit',
  unity_edit_success: 'unity_edit_success',
  unity_edit_failed: 'unity_edit_failed',

  unity_edit_configs_view: 'unity_edit_configs_view',
  unity_edit_configs: 'unity_edit_configs',
  unity_edit_configs_success: 'unity_edit_configs_success',
  unity_edit_configs_failed: 'unity_edit_configs_failed',

  visit_page_view: 'visit_page_view',
  visit_page_sign_in: 'visit_page_sign_in',
  visit_page_photo: 'visit_page_photo',
  visit_page_schedule: 'visit_page_schedule',
  visit_page_schedule_success: 'visit_page_schedule_success',
  visit_page_schedule_failed: 'visit_page_schedule_failed',

  visit_confirmation_by_user: 'visit_confirmation_by_user',
  visit_confirmation_by_user_success: 'visit_confirmation_by_user_success',
  visit_confirmation_by_user_failed: 'visit_confirmation_by_user_failed',
  visit_confirmation_by_owner: 'visit_confirmation_by_owner',
  visit_confirmation_by_owner_success: 'visit_confirmation_by_owner_success',
  visit_confirmation_by_owner_failed: 'visit_confirmation_by_owner_failed',

  visit_cancel_by_user: 'visit_cancel_by_user',
  visit_cancel_by_user_success: 'visit_cancel_by_user_success',
  visit_cancel_by_user_failed: 'visit_cancel_by_user_failed',
  visit_cancel_by_owner: 'visit_cancel_by_owner',
  visit_cancel_by_owner_success: 'visit_cancel_by_owner_success',
  visit_cancel_by_owner_failed: 'visit_cancel_by_owner_failed',

  welcome_view: 'welcome_view',
};
